<template>
  <div class="container mx-auto scale-50">
    <div class="flex place-content-center items-center border-0 p-5 scale-50">
      <div class="p-0 scale-75">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:dc="http://purl.org/dc/elements/1.1/"
          xmlns:cc="http://web.resource.org/cc/"
          xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
          version="1.1"
          baseProfile="full"
          width="600"
          height="600"
          viewBox="0 0 600 600"
          preserveAspectRatio="xMidYMid meet"
          id="svg_document"
          style="zoom: 1"
          visibility="visible"
        >
          <title id="svg_document_title" visibility="visible">climecontrols.svg</title>
          <defs id="svg_document_defs" visibility="visible"></defs>
          <g id="main_group" visibility="visible">
            <text
              stroke="none"
              style="outline-style: none"
              id="text1"
              stroke-width="1px"
              x="279px"
              text-rendering="geometricPrecision"
              font-family="Roboto"
              fill="dimgray"
              font-size="450px"
              y="461px"
              transform=""
              text-anchor="middle"
            ></text>
          </g>
          <circle
            stroke="3"
            id="thing_loading"
            cy="50"
            fill="white"
            r="35"
            cx="300"
            visibility="visible"
          ></circle>
          <circle
            stroke="3"
            id="thing_constant"
            cy="300"
            fill="white"
            r="35"
            cx="550"
            visibility="visible"
          ></circle>
          <circle
            stroke="white"
            id="trajectory"
            cy="300"
            stroke-width="15"
            fill="none"
            r="250"
            cx="300"
            visibility="visible"
          ></circle>
          <svg
            stroke="dimgrey"
            style="zoom: 1"
            height="640"
            id="svg1"
            width="640"
            fill="none"
          ></svg>
          <svg
            class="svg-icon"
            style="
              width: 1em;
              height: 1em;
              vertical-align: middle;
              fill: currentColor;
              overflow: hidden;
              zoom: 1;
            "
            viewBox="0 80 1024 1024"
            version="1.1"
            id="svg2"
          >
            <path
              d="M512,938.666687 C323.481598,938.666687 170.666656,785.851746 170.666656,597.333313 S323.481598,256 512,256 s341.333344,152.814926 341.333344,341.333344 s-152.814926,341.333344 -341.333344,341.333344 m216.917343,-206.4384 v-3.584 c0,-31.470936 0,-45.841068 -22.254934,-58.572803 a251.699203,251.699203 0 0 0 -22.016001,-11.093333 c-12.526934,-5.700266 -20.821333,-9.4208 -32.017067,-25.8048 a410.077881,410.077881 0 0 1 -3.959467,-5.870934 c-11.776,-17.92 -20.2752,-30.822401 -52.633602,-25.702398 c-63.658669,10.103466 -68.369072,21.2992 -71.167999,40.209068 l-0.443733,3.106133 c-4.130134,27.648001 -4.881067,36.932266 6.656,49.049603 c43.178665,45.294933 69.051735,77.960533 76.902397,97.075203 c3.822934,9.3184 13.653335,37.546669 6.894933,65.467735 a279.381317,279.381317 0 0 0 107.554138,-76.35627 c3.754667,-12.765866 6.485333,-28.672001 6.485333,-47.923199 M512,318.566406 c-79.086937,0 -150.528,32.972801 -201.250137,85.879471 c6.0416,4.1984 11.298134,10.103466 14.916267,18.227201 c6.963201,15.598933 6.963201,31.675734 6.963201,45.909332 c0,11.195734 0,21.845335 3.584,29.525333 c4.9152,10.513066 26.146133,15.018666 44.885334,18.909866 c6.724266,1.4336 13.619201,2.8672 19.899733,4.608 c17.271467,4.778666 30.651733,20.309334 41.335468,32.768002 c4.437334,5.154134 11.025066,12.765866 14.336,14.677333 c1.706666,-1.2288 7.202134,-7.202134 9.898667,-16.9984 c2.116266,-7.509334 1.501866,-14.1312 -1.536,-17.749334 c-19.114668,-22.528 -18.056534,-65.877335 -12.151467,-81.885864 c9.284266,-25.224533 38.297604,-23.3472 59.528534,-21.981867 c7.918934,0.512 15.360001,1.024 20.957867,0.3072 c21.230934,-2.6624 27.784534,-34.986668 32.392532,-41.301334 c9.966933,-13.653335 40.482136,-34.235733 59.391998,-46.933334 A277.777069,277.777069 0 0 0 512,318.566406 "
              id="path1"
              new_attribute_1="0"
              fill="white"
            ></path>
          </svg>
        </svg>
      </div>
      <div class="text-white font-sans text-9xl align-middle">ClimeControls</div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: black;
}

nav {
  padding: 30px;
}
img {
  padding-top: 100px;
  max-width: 90%;
  max-height: 100vh;
  height: auto;
}

body {
  background-color: black;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
